import React, { useEffect, useState } from 'react';
import '../SCSS/styles.scss';
import {Link, useParams} from 'react-router-dom';
import Api from '../Util/Api';
import ValidationError from '../Util/ValidationError';
import Popup from '../Components/popup/Popup';
import { ReactComponent as WhiteCheck } from '../Icons/check-white.svg';
import LoadingIndicator from "./loadingindicator/LoadingIndicator";

export default function ThankYou(){
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingOverlayEnabled, setIsLoadingOverlayEnabled] = useState(true);
    const [thankYouMessage, setThankYouMessage] = useState();
    const params = useParams();
    const FestivalEditionID = params.FestivalEditionID;
    const AppGUID = params.AppGUID;

    useEffect(()=>{
        getThankYouMessage();
    },[])

    if(isLoadingOverlayEnabled && isLoading) {
        return <LoadingIndicator key={"loading-indicator-post"} indicatorColor={"light"} showBackgroundOverlay={true} isFullscreen={true}/>
    }

    return(
        <div className="thankyou">
            <WhiteCheck/>
            
            <h1>We're all done!</h1>

            <div className="text-container" style={{whiteSpace: 'pre-line'}}>
                <p>{thankYouMessage}</p>
            </div>

            <div className="invitation-action">
                <Link to={"/closeWebView"}>
                    <button className="accept-action" onClick={localStorage.clear()}>OK</button>
                </Link>
            </div>
        </div>
    );

    function getThankYouMessage(){
        function validator(response){
            if(!response.data.ThankYouMessage){
                throw new ValidationError("ThankYouMessage is not valid");
            }
        }

        function onSuccess(response){
            setThankYouMessage(response.data.ThankYouMessage);
            setIsLoading(false);
        }

        function onFailure(error){
            Popup.showDefaultRequestError(
                function () {
                    getThankYouMessage();
                },
            )
        }

        setIsLoading(true);
        Api.get(`/survey/getText?FestivalEditionID=${FestivalEditionID}&dbField=ThankYouMessage`, validator, onSuccess, onFailure, {headers:{'App-Guid': AppGUID}});
    }
}