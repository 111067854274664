import React from "react";
import Range from "./Range";
import Image from "./Image";

function FormField({ question, register, getValues, isAnswered }) {
    let disabledStyling = isAnswered ? ' disabled-styling' : '';

    switch (question.questionType) {
        case "text":
            return (
                <div className="input-container">
                    <input className="question-text" type="text" name={"Key"+question.questionID} ref={register({ required: question.required })} disabled={isAnswered}/>
                </div>
            )
        case "textarea":
            return (
                <div className="input-container">
                    <textarea className="question-textarea" placeholder={"Type your answer here"} name={"Key"+question.questionID} ref={register({ required: question.required })} disabled={isAnswered}/>
                </div>
            )
        case "number":
            return (
                <div className="input-container">
                    <input className="question-number" type="number" name={"Key"+question.questionID} ref={register({ required: question.required })} disabled={isAnswered}/>
                </div>
            )       
        case "checkbox":
            return (
                <div className="input-container">
                    <p className="checkbox-hint">Multiple options possible</p>
                    {question.option.map((option, index)=>{
                        return(       
                            <label className={"question-label-options" + disabledStyling} key={index}>
                                <input type="checkbox" name={"Key"+question.questionID} value={option} ref={register({ required: question.required })} disabled={isAnswered}/>
                                <span>{option}</span>    
                            </label>
                        ); 
                    })}
                </div>
            )      
        case "radio":
            return(
                <div className="input-container">
                    {question.option.map((option, index)=>{
                        return(
                            <label className={"question-label-options"+ disabledStyling} key={index}>
                                <input type="radio" name={"Key"+question.questionID} value={option} ref={register({ required: question.required })} disabled={isAnswered}/>
                                <span>{option}</span> 
                            </label>   
                        );
                    })}
                </div>
            )        
        case "select":
            return (
                <div className="select-container">
                    <select className="question-select" name={"Key"+question.questionID} ref={register({ required: question.required })} disabled={isAnswered}>
                        {question.option.map((option, index)=>{
                            return(
                                <option value={option} key={index}>{option}</option>
                            )
                        })}
                    </select>
                </div>
            ) 
        case "range":
            return (
                <div className={"input-container" + disabledStyling}>
                    <Range question={question} register={register} getValues={getValues} disabled={isAnswered}/>
                </div>
            )       
        case "date":
            return (
                <div className="input-container">
                    <div className="date-container">
                        <input type="date" name={"Key"+question.questionID}  placeholder={!isAnswered && 'Date'} required aria-required="true" ref={register({ required: question.required })} disabled={isAnswered}/>
                        <span className="date-button"/>
                    </div>
                </div>
            )
        case "image":
            return(
                <div className={"input-container" + disabledStyling}>
                    <Image question={question} register={register} disabled={isAnswered}/>
                </div>
            )
        default:
            return null
    }
}

export default FormField