import React from 'react';
import { ReactComponent as ClearIcon } from '../Icons/clear.svg';
import {Link} from "react-router-dom";

export default function Header(){
    return(
        <div className={'header'}>
            <h2>Mystery Guest</h2>
            <Link className="header-action-right" to={"/closeWebView"}>
                <ClearIcon/>
            </Link>
        </div>
    )
}