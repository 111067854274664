import React, { useEffect, useState } from 'react';
import '../SCSS/styles.scss';
import { Link, useParams } from "react-router-dom";
import Api from '../Util/Api';
import ValidationError from '../Util/ValidationError';
import Popup from '../Components/popup/Popup';
import { ReactComponent as MysteryGuestIcon } from '../Icons/mystery_guest_icon_white.svg';
import LoadingIndicator from "./loadingindicator/LoadingIndicator";

export default function Explanation(){
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingOverlayEnabled, setIsLoadingOverlayEnabled] = useState(true);
    const [explanationText, setExplanationText] = useState();
    const params = useParams();
    const FestivalEditionID = params.FestivalEditionID;
    const AppGUID = params.AppGUID;

    useEffect(()=>{
        getExplanationText();
    },[])

    if(isLoadingOverlayEnabled && isLoading) {
        return <LoadingIndicator key={"loading-indicator-post"} indicatorColor={"light"} showBackgroundOverlay={true} isFullscreen={true}/>
    }

    return(
        <div className="explanation">
            <MysteryGuestIcon/>

            <h1>Mystery Guest</h1>

            <div className="text-container" style={{whiteSpace: 'pre-line'}}>
                <p>{explanationText}</p>
            </div>

            <div className="invitation-action">
                <Link to={`/${FestivalEditionID}/${AppGUID}/confirmation`}>
                    <button className="accept-action">Yes, count me in!</button>
                </Link>

                <Link to={"/closeWebView"}>
                    <button  className="decline-action">Maybe later!</button>
                </Link>
            </div>
        </div>
    );

    function getExplanationText(){
        function validator(response){
            if(!response.data.ExplanationText){
                throw new ValidationError("ExplanationText is not valid");
            }
        }

        function onSuccess(response){
            setExplanationText(response.data.ExplanationText)
            setIsLoading(false);
        }

        function onFailure(error){
            Popup.showDefaultRequestError(
                function () {
                    getExplanationText();
                },
            )
        }

        setIsLoading(true);
        Api.get(`/survey/getText?FestivalEditionID=${FestivalEditionID}&dbField=ExplanationText`, validator, onSuccess, onFailure, {headers:{'App-Guid': AppGUID}});
    }
}