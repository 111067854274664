import React from 'react';
import '../SCSS/styles.scss';
import { ReactComponent as MysteryGuestIcon } from '../Icons/mystery_guest_icon_white.svg';
import {Link, useParams} from "react-router-dom";
import Header from "./Header";

export default function NoQuestionsAvailable(){
    const params = useParams();
    const AnswersAvailable = params.AnswersAvailable;
    const FestivalEditionID = params.FestivalEditionID;
    const AppGUID = params.AppGUID;

    return(
        <div className="globalform-wrapper">
            <Header/>
            <div className="no-questions-available" style={{height: 'calc(100% - 57px)'}}>
                <MysteryGuestIcon/>

                <h1>You're done for now!</h1>

                <div className="text-container" style={{whiteSpace: 'pre-line'}}>
                    <p>You will receive a notification when new questions are available.</p>
                </div>

                <div className="invitation-action">
                    <Link to={`/${FestivalEditionID}/${AppGUID}/globalform`}>
                        <button className="accept-action">{AnswersAvailable==='true' ? 'View sent answers' : 'Retry'}</button>
                    </Link>
                </div>

            </div>
        </div>

    );
}