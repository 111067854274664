import React, { useState, useEffect, useCallback } from 'react';
import '../SCSS/range.scss';

export default function Range({ question, register, getValues, disabled}){
    const initialValue = () => {
        const valueObject = getValues();

        if(!valueObject["Key"+question.questionID]){
            return 0
        }

        return valueObject["Key"+question.questionID]
    }

    const [rangeValue, setRangeValue] = useState(initialValue);
    const [rangeLeft, setRangeLeft] = useState();

    const calculatePosition = useCallback(() => {
        const newValue = Number( (rangeValue - question.range.rangeMin) * 100 / (question.range.rangeMax - question.range.rangeMin) ),
        newPosition = 10 - (newValue * 0.2);
        setRangeLeft(`calc(${newValue}% + (${newPosition}px))`);
    }, [question.range.rangeMin, question.range.rangeMax, rangeValue]);

    useEffect(() => {
        calculatePosition();
    }, [rangeValue, calculatePosition])

    const handleChange = useCallback( 
        event => {
            setRangeValue(event.target.value)
        }, []
    )

    return(
        <div className="range-wrap">
            <input 
                type="range"
                name={"Key"+question.questionID}
                ref={register({ required: question.required })}
                min={question.range.rangeMin}
                max={question.range.rangeMax}
                value={rangeValue}
                onChange={handleChange}
                className="input-range"
                disabled={disabled}
            />

            <div style={{left: rangeLeft}} className="range-value" id="rangeV">
                <span>{question.range.rangeTypeOfValue}{rangeValue}</span>
            </div>
        </div>
    )
}