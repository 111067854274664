import React, { useEffect, useState } from 'react';
import '../SCSS/styles.scss';
import {useHistory, useParams} from 'react-router-dom';
import Api from '../Util/Api';
import ValidationError from '../Util/ValidationError';
import Popup from '../Components/popup/Popup';
import Header from './Header';
import Step from './Step';
import LoadingIndicator from "./loadingindicator/LoadingIndicator";

export default function GlobalForm(){
    const [state, setState] = useState({});
    const [questionData, setQuestionData] = useState({});
    const [totalAmountOfQuestions, setTotalAmountOfQuestions] = useState();
    const [totalSteps, setTotalSteps] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingOverlayEnabled, setIsLoadingOverlayEnabled] = useState(true);
    const history = useHistory();
    const params = useParams();
    const FestivalEditionID = params.FestivalEditionID;
    const AppGUID = params.AppGUID;
    const globalFormID = 'globalForm'+FestivalEditionID;
    const currentStepID = 'currentStep'+FestivalEditionID;

    useEffect(()=>{
        getQuestionsBySurvey();
    },[])

    useEffect(()=>{
        // When there are no questions available start at the last answered question
        if(state.currentStep===totalSteps){
            setState(prevState=>({
                globalForm: prevState.globalForm,
                currentStep: prevState.currentStep-1
            }));
        }
    },[totalSteps])

    useEffect(()=>{
        localFormData();
    },[])

    function localFormData(){
        if(!localStorage.getItem(globalFormID)){
            localStorage.setItem(globalFormID, '{}')
            localStorage.setItem(currentStepID, 0)

            setState({
                globalForm: {},
                currentStep: 0
            })
        }
        setState({
            globalForm: JSON.parse(localStorage.getItem(globalFormID)),
            currentStep: localStorage.getItem(currentStepID)*1
        })
    }

    function determineStep(currentStep, newStep, newGlobalForm){
        if(newStep!==totalSteps){
            return {
                globalForm: newGlobalForm,
                currentStep: newStep
            };  
        }
        return {
            globalForm: newGlobalForm,
            currentStep: currentStep
        };
    }

    function determineSave(newStep, newGlobalForm, values){
        if(newStep!==totalSteps){
            return saveSingleAnswer(values);
        }
        return saveFullForm(newGlobalForm)
    }

    const next = (values, checkIfAnswered) => {
        setState(prevState => {
            const newGlobalForm = {
            ...prevState.globalForm,
            ...values
            };

            const newStep = Math.min(prevState.currentStep + 1, totalSteps);

            if(!checkIfAnswered){
                determineSave(newStep, newGlobalForm, values);
                localStorage.setItem(globalFormID, JSON.stringify(newGlobalForm));
                localStorage.setItem(currentStepID, newStep);
            }

            return determineStep(prevState.currentStep, newStep, newGlobalForm);
        });
    };

    const previous = () => {
        setState(prevState => {
          return {
            ...prevState,
            currentStep: Math.max(0, prevState.currentStep - 1)
          };
        });
    };

    const CurrentQuestion = () => {
        return(
            Object.keys(questionData).map(function(key, index) {
                let pairKey = `Key${questionData[key].questionID}`
                let pairValue = state.globalForm[pairKey]
                let pair = {[pairKey]: pairValue}
                
                if(state.currentStep !== index)return null;
                
                return(
                    <Step
                        initialState={pair} 
                        previous={previous} 
                        next={next}
                        key={index}
                        index={index+1}
                        totalSteps={totalSteps}
                        question={questionData[key]}
                    />
                )
            })
        )
        
    }

    if(isLoadingOverlayEnabled && isLoading) {
        return <LoadingIndicator key={"loading-indicator-post"} indicatorColor={"light"} showBackgroundOverlay={true} isFullscreen={true}/>
    }

    return(
        <div className="globalform-wrapper">
            <Header/>
            <CurrentQuestion/>
        </div>
    );

    function getQuestionsBySurvey(){
        function validator(response){
            let formResponse = response.data.questions;

            if(!formResponse){
                throw new ValidationError("No questions available");
            }

            formResponse.forEach(question=>{
                let questionMissesAttribute  = !question.questionID || !question.questionText || !question.questionType;
                if (questionMissesAttribute ) {
                    throw new ValidationError("QuestionID, QuestionText or QuestionType are missing");
                }
            })
        }

        function onSuccess(response){
            setQuestionData(response.data.questions);
            setTotalAmountOfQuestions(response.data.totalAmountOfQuestions);
            setTotalSteps(response.data.questions.length);
            setIsLoading(false);
        }

        function onFailure(error){

            if(error.message === "No questions available"){
                return history.push(`/${FestivalEditionID}/${AppGUID}/noquestionsavailable/false`);
            }

            Popup.showDefaultRequestError(
                function () {
                    getQuestionsBySurvey();
                },
            )
        }

        Api.get(`/survey/getQuestions?FestivalEditionID=${FestivalEditionID}`, validator, onSuccess, onFailure, {headers:{'App-Guid': AppGUID}});
    }

    // Save after each question
    function saveSingleAnswer(answer){
        function validator(response){
            if (!(response.data.data && response.data.data.success)) {
                throw new ValidationError("Couldn't post successfully")
            }
        }
        function onSuccess(response){
            console.log(response);
        }
        function onFailure(){
            console.log("Unable to save answer");
        }
        Api.post(`/survey/saveAnswers`, validator, onSuccess, onFailure, {headers:{'App-Guid': AppGUID}}, answer)
    }
    
    // Save at the last question
    function saveFullForm(finalForm){
        function validator(response){
            if (!(response.data.data && response.data.data.success)) {
                throw new ValidationError("Couldn't post successfully")
            }
        }

        function onSuccess(response){
            if(state.currentStep+1!==totalAmountOfQuestions){
                return history.push(`/${FestivalEditionID}/${AppGUID}/noquestionsavailable/true`);
            }
            return history.push(`/${FestivalEditionID}/${AppGUID}/thankyou`);
        }

        function onFailure(error){
            function tryAgain() {
                saveFullForm(state.globalForm);
            }

            const config = {
                title: "Unable to save answers",
                text: "Please try again",
                confirmButtonText: "Retry",
                cancelButtonText: "Cancel"
            };

            Popup.show(config, tryAgain);
        }
        setIsLoading(true);
        Api.post(`/survey/saveAnswers`, validator, onSuccess, onFailure, {headers:{'App-Guid': AppGUID}}, finalForm)
    }
}
   