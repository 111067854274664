import React, { useEffect, useState } from 'react';
import '../SCSS/styles.scss';
import {Link, useHistory, useParams} from "react-router-dom";

export default function Loading(){
    const history = useHistory();
    const params = useParams();
    const FestivalEditionID = params.FestivalEditionID;
    const AppGUID = params.AppGUID;

    function determineRoute() {
        if(localStorage.getItem('globalForm'+FestivalEditionID)){
            return history.push(`/${FestivalEditionID}/${AppGUID}/globalform`);
        }
        return history.push(`/${FestivalEditionID}/${AppGUID}/explanation`);
    }

    return(
        <>{determineRoute()}</>
    );
}