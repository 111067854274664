import React from "react";
import { useForm } from 'react-hook-form';
import { ReactComponent as NextIcon } from '../Icons/navigate_next.svg';
import { ReactComponent as BackIcon } from '../Icons/navigate_before.svg';
import variables from '../SCSS/variables.scss';
import FormField from '../Components/FormField'
import {Link, useParams} from "react-router-dom";

export default ({ initialState, previous, next, question, index, totalSteps }) => {
    const params = useParams();
    const FestivalEditionID = params.FestivalEditionID;
    const AppGUID = params.AppGUID;
    const { register, handleSubmit, errors, getValues } = useForm({
      defaultValues: initialState
    });
    
    const checkIfAnswered = () => {
      if(!initialState["Key"+question.questionID]){
        return false;
      }
      return true;
    }

    const onSubmit = values => {
      return next(values, checkIfAnswered());
    };

    const BackButton = () => {
      if(index !== 1){
        return(
          <button type="button" onClick={previous} className='backButton'>
            <BackIcon fill={variables.secondaryColor}/>
          </button>
        )
      }
      return null
    }

    const NextButton = () => {
      if(index !== totalSteps){
        return (
          <button type="submit" className='nextButton'>
            <NextIcon fill={variables.primaryColorDark}/>
          </button>
        )
      }

      if(!checkIfAnswered()){
          return (
              <button type="submit" className='submitButton'>
                  Send<NextIcon fill={variables.primaryColorDark}/>
              </button>
          )
      }
      return (
          <Link to={`/${FestivalEditionID}/${AppGUID}/noquestionsavailable/true`}>
              <button className='nextButton'>
                  <NextIcon fill={variables.primaryColorDark}/>
              </button>
          </Link>
      )
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className='question-container'>
                <h3>{question.questionText}</h3>
                <FormField question={question} register={register} getValues={getValues} isAnswered={checkIfAnswered()}/>
                {errors["Key"+question.questionID] && <span className="formError">This is required</span>}
            </div>

            <div className="stepCounter">
                <p>{`${index} of ${totalSteps}`}</p>
            </div>

            <div className='formAction'>
                <BackButton/>
                <NextButton/>
            </div>
        </form>
    );
};