import React, {useEffect} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import GlobalForm from '../Components/GlobalForm';
import Explanation from '../Components/Explanation';
import Confirmation from '../Components/Confirmation';
import ThankYou from '../Components/ThankYou';
import Loading from "./Loading";
import NoQuestionsAvailable from "./NoQuestionsAvailable";
import Api from '../Util/Api';
import ValidationError from '../Util/ValidationError';
import Popup from '../Components/popup/Popup';
import LoadingIndicator from "./loadingindicator/LoadingIndicator";

function App() {
    let params = window.location.pathname.split('/');
    let FestivalEditionID = params[1];
    let AppGUID = params[2];

    useEffect(()=>{
        getBackgroundImage();
    },[])

    return (
        <Router>
            <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap" rel="stylesheet"/>
            <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap" rel="stylesheet"/>
            <Switch>
                <Route path="/:FestivalEditionID/:AppGUID" exact component={Loading}/>
                <Route path="/:FestivalEditionID/:AppGUID/explanation" exact component={Explanation}/>
                <Route path="/:FestivalEditionID/:AppGUID/confirmation" exact component={Confirmation}/>
                <Route path="/:FestivalEditionID/:AppGUID/globalform" exact component={GlobalForm}/>
                <Route path="/:FestivalEditionID/:AppGUID/thankyou" exact component={ThankYou}/>
                <Route path="/:FestivalEditionID/:AppGUID/noquestionsavailable/:AnswersAvailable" exact component={NoQuestionsAvailable}/>
            </Switch>
        </Router>
    );

    function getBackgroundImage(){
        function validator(response){
            if(!response.data.backgroundImageUrl){
                throw new ValidationError("Background image is not present");
            }
        }

        function onSuccess(response){
            document.body.style.backgroundImage = `url(${response.data.backgroundImageUrl})`;
        }

        function onFailure(error){
            Popup.showDefaultRequestError(
                function () {
                    getBackgroundImage();
                },
            )
        }

        Api.get(`/survey/getBackgroundImage?FestivalEditionID=${FestivalEditionID}`, validator, onSuccess, onFailure, {headers:{'App-Guid': `${AppGUID}`}});
    }
}

export default App;
