import React, {useEffect, useState} from 'react';
import '../SCSS/styles.scss';
import { Link, useParams } from "react-router-dom";
import ValidationError from "../Util/ValidationError";
import Popup from "./popup/Popup";
import Api from "../Util/Api";
import { ReactComponent as MysteryGuestIcon } from '../Icons/mystery_guest_icon_white.svg';
import LoadingIndicator from "./loadingindicator/LoadingIndicator";

export default function Confirmation(){
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingOverlayEnabled, setIsLoadingOverlayEnabled] = useState(true);
    const [confirmationText, setConfirmationText] = useState();
    const params = useParams();
    const FestivalEditionID = params.FestivalEditionID;
    const AppGUID = params.AppGUID;

    useEffect(()=>{
        getConfirmationText();
    },[])

    if(isLoadingOverlayEnabled && isLoading) {
        return <LoadingIndicator key={"loading-indicator-post"} indicatorColor={"light"} showBackgroundOverlay={true} isFullscreen={true}/>
    }

    return(
        <div className="confirmation">
            <MysteryGuestIcon/>
            
            <h1>Welcome,<br/>Mystery Guest</h1>

            <div className="text-container" style={{whiteSpace: 'pre-line'}}>
                <p>{confirmationText}</p>
            </div>

            <div className="invitation-action">
                <Link to={`/${FestivalEditionID}/${AppGUID}/globalForm`}>
                    <button className="accept-action">Start</button>
                </Link>
                <Link to={"/closeWebView"}>
                    <button className="decline-action">Not now</button>
                </Link>
            </div>
        </div>
    );

    function getConfirmationText(){
        function validator(response){
            if(!response.data.ConfirmationText){
                throw new ValidationError("ConfirmationText is not valid");
            }
        }

        function onSuccess(response){
            setConfirmationText(response.data.ConfirmationText);
            setIsLoading(false);
        }

        function onFailure(error){
            Popup.showDefaultRequestError(
                function () {
                    getConfirmationText();
                },
            )
        }

        setIsLoading(true);
        Api.get(`/survey/getText?FestivalEditionID=${FestivalEditionID}&dbField=ConfirmationText`, validator, onSuccess, onFailure, {headers:{'App-Guid': AppGUID}});
    }
}