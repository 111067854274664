import React from 'react';
import '../SCSS/image.scss';

export default function Image({ question, register, disabled}){
    function getImageName(url){
        let parts = url.split('/');
        let lastSegment = parts.pop() || parts.pop();
        return lastSegment;
    }

    return(
        <div className="image-container">
            {question.images.map((image, index)=>{
                return(
                    <input key={index} type="radio" name={"Key"+question.questionID} value={getImageName(image)} ref={register({ required: question.required })} disabled={disabled} style={{
                        background:`url(${image})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}/>
                );
            })}
        </div>
    )
}